import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
    Form_AddUser_Processing = false;
    Form_AddUser: FormGroup;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        this.Form_AddUser = _FormBuilder.group({
            OperationType: 'new',
            Task: this._HelperService.AppConfig.Api.Core.Register,
            AccountTypeCode: this._HelperService.AppConfig.AccountType.Merchant,
            AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,
            RegistrationSourceCode: this._HelperService.AppConfig.RegistrationSource.System,
            OwnerKey: null,
            SubOwnerKey: null,
            BankKey: null,
            Password: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(20),
                    Validators.pattern(
                        this._HelperService.AppConfig.ValidatorRegex.Password
                    )
                ])
            ],
            // DisplayName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(25)])],
            Name: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
            // FirstName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(128)])],
            // LastName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(128)])],
            EmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            MobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(14)])],
            // ContactNumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(18)])],
            // SecondaryEmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            // WebsiteUrl: [null, Validators.compose([Validators.minLength(2), Validators.pattern(this._HelperService.AppConfig.ValidatorRegex.WebsiteUrl)])],
            // Description: null,
            // Address: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(256)])],
            CountValue: 0,
            AverageValue: 0,
            ApplicationStatusCode: null,
            EmailVerificationStatus: 0,
            EmailVerificationStatusDate: null,
            NumberVerificationStatus: 0,
            NumberVerificationStatusDate: null,
            CountryKey: null,
            RegionKey: null,
            RegionAreaKey: null,
            CityKey: null,
            CityAreaKey: null,
            BusinessCategories: null,
            AccountLevelCode: 'accountlevel.ca',
            SubscriptionKey: 'merchantbasic',
            AccountPercentage: 0,
            StatusCode: this._HelperService.AppConfig.Status.Active,
            Owners: [],
            Configuration: [],
        })
    }
    ngOnInit() {
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Account);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Device);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.Location);
        this._HelperService.DeleteStorage(this._HelperService.AppConfig.Storage.OReqH);
        this._HelperService.SetNetwork();
    }
    Form_AddUser_Process(_FormValue: any) {
        _FormValue.DisplayName = _FormValue.Name;
        this.Form_AddUser_Processing = true;
        _FormValue.Configurations = [
            {
                SystemName: 'rewardpercentage',
                Value: 3,
            },
            {
                SystemName: 'rewardmaxinvoiceamount',
                Value: '0',
            },
            {
                SystemName: 'rewarddeductiontype',
                Value: 'Prepay',
                HelperCode: 'rewarddeductiontype.prepay',
            },
            {
                SystemName: 'maximumrewarddeductionamount',
                Value: '0',
            },

            {
                SystemName: 'thankucashplus',
                Value: '0',
            },
            {
                SystemName: 'thankucashplusrewardcriteria',
                HelperCode: 'rewardcriteriatype.mininvoice',
                Value: '2000',
            },
            {
                SystemName: 'thankucashplusmintransferamount',
                Value: '2000',
            },

            {
                SystemName: 'settlementScheduletype',
                HelperCode: 'settlementSchedule.daily',
                Value: 'Daily',
            },
            {
                SystemName: 'minimumsettlementamount',
                Value: '1',
            },
        ];
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, _FormValue);
        _OResponse.subscribe(
            _ResponseI => {
                this.Form_AddUser_Processing = false;
                if (_ResponseI.Status == this._HelperService.StatusSuccess) {
                    this._HelperService.NotifySuccess(_ResponseI.Message);
                    var pData = {
                        Task: 'login',
                        UserName: _FormValue.EmailAddress,
                        Password: _FormValue.Password,
                    };
                    let _OResponse: Observable<OResponse>;
                    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
                    _OResponse.subscribe(
                        _Response => {
                            if (_Response.Status == this._HelperService.StatusSuccess) {
                                this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
                                var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
                                _StorageReqH.hcuak = _Response.Result['AccessKey'];
                                _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
                                this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
                                this._HelperService.RefreshHelper();
                                var PostData = {
                                    Task: "creditmerchantwallet",
                                    AccountId: _Response.Result.UserAccount.AccountId,
                                    InvoiceAmount: 10000,
                                    ReferenceNumber: "ONBOARDINGCREDIT",
                                };
                                let _OResponse: Observable<OResponse>;
                                _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.ThankU, PostData);
                                _OResponse.subscribe(
                                    _Response => {
                                        // debugger;
                                        this._HelperService.IsFormProcessing = false;
                                        if (_Response.Status == this._HelperService.StatusSuccess) {
                                            this.Form_AddUser.reset();
                                            window.location.href = this._HelperService.AppConfig.Pages.System.MerchantSetup;
                                        } else {
                                            this._HelperService.NotifySuccess(_Response.Message);
                                        }
                                    },
                                    _Error => {
                                        // debugger;
                                        this._HelperService.IsFormProcessing = false;
                                        this._HelperService.HandleException(_Error);
                                    }
                                );
                            }
                            else {
                                this._HelperService.NotifyError(_Response.Message);
                            }
                        },
                        _Error => {
                            this._HelperService.HandleException(_Error);
                        });
                }
                else {
                    this._HelperService.NotifyError(_ResponseI.Message);
                }
            },
            _Error => {
                this.Form_AddUser_Processing = false;
                this._HelperService.HandleException(_Error);
            });
    }



}
