import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/onboarding/register/register.component';
import { RegisterStoreComponent } from './auth/onboarding/registerstore/registerstore.component';
import { ForgotPasswordComponent } from './auth/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './auth/resetpassword/resetpassword.component';
import { AccessComponent } from './auth/access/access.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslaterModule, HelperService, DataHelperService, FilterHelperService } from './service/service';
import { AgmCoreModule } from '@agm/core';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { Ng2FileInputModule } from 'ng2-file-input';
import { AgmOverlays } from "agm-overlays"
import { Ng5SliderModule } from 'ng5-slider';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ArchwizardModule } from 'angular-archwizard';
import { Angular4PaystackModule } from 'angular4-paystack';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { RegisterRewardComponent } from './auth/onboarding/registerreward/registerreward.component';
import { RegisterCompleteComponent } from './auth/onboarding/registercomplete/registercomplete.component';
import { ChartsModule } from 'ng2-charts';
import { from } from 'rxjs';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TestcomponentComponent } from './testcomponent/testcomponent.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RegisterStoreComponent,
    RegisterRewardComponent,
    RegisterCompleteComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccessComponent,
    TestcomponentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslaterModule,
    Ng5SliderModule,
    ArchwizardModule,
    ImageCropperModule,
    AgmOverlays,
    Angular4PaystackModule,
    NgxJsonViewerModule,
    GooglePlaceModule,
    ChartsModule,
    NgProgressModule,
    NgProgressHttpModule,

    LoadingBarModule,
    LoadingBarHttpClientModule,
    
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB9DMDX78ll840bMxjGL4WUfjAYXfi8vZo'
    }),
    Ng2FileInputModule.forRoot(),
  ],
  providers: [
    HelperService,
    DataHelperService,
    FilterHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
