import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-testcomponent',
  templateUrl: './testcomponent.component.html',
  styleUrls: ['./testcomponent.component.css']
})
export class TestcomponentComponent implements OnInit {

  numbe: string = '50%';
  public showtable: boolean = true;

  constructor(private _ChangeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit() {

    setTimeout(() => {
      this.numbe = '90%';
    }, 3000);
  }

}
